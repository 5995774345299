import React from "react"
import { navigate } from "gatsby"
import qs from "qs"

import Medusa from "../../services/api"
import SEO from "../../components/seo"
import LoadingSpinner from "../../components/ui/LoadingSpinner"
import StoreContext from "../../context/StoreContext"

class PaymentCompleted extends React.Component {
  async componentDidMount() {
    const cachedCart = localStorage.getItem("medusa::cache")
    const parsedCart = JSON.parse(cachedCart)
    const cart = await Medusa.cart
      .retrieve(parsedCart.id)
      .then(({ data }) => data.cart)

    let order

    if (
      cart.payment_session &&
      cart.payment_session.provider_id === "mobilepay-adyen"
    ) {
      const [, ...search] = window.location.search
      const parsed = qs.parse(search)

      order = await Medusa.cart
        .updatePaymentSession(cart.id, "mobilepay-adyen", {
          data: {
            details: {
              redirectResult: parsed.payload,
            },
          },
        })
        .then(async ({ data }) => {
          return await Medusa.cart.completeCart(cart.id)
        })
    } else {
      order = await Medusa.cart.completeCart(cart.id)
    }

    if (order) {
      let params = []

      if ("data" in order.data) {
        if (order.data.data.id.startsWith("cart")) {
          navigate("/checkout", { replace: true })
          return
        }

        params.push(`o=${order.data.data.id}`)
      } else if ("order" in order.data) {
        params.push(`o=${order.data.order.id}`)
      }

      this.context.clearCart()
      navigate(`/checkout/done?${params.join("&")}`, {
        replace: true,
      })
    } else {
      navigate(`/checkout`, {
        replace: true,
      })
    }
  }

  render() {
    return (
      <>
        <SEO title="Please wait..." />
        <LoadingSpinner />
      </>
    )
  }
}

PaymentCompleted.contextType = StoreContext

export default PaymentCompleted
